import {
  AppButton as AppButtonNew,
  type AppButtonProps as AppButtonPropsNew,
} from '~/zidmui/components/app-button';

//
//

/**
 * @deprecated Import from zidmui instead.
 */
export type AppButtonProps = AppButtonPropsNew;

/**
 * @deprecated Import from zidmui instead.
 */
export const AppButton = AppButtonNew;
